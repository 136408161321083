





































































































































































































































































































































































.three {
  padding: 40px 0 70px 120px;
  .header-tag {
    display: flex;
    align-items: center;
    .header-tag-box {
      width: 6px;
      height: 12px;
      background-color: #2821fc;
      border-radius: 3px;
    }
  }
  .three-container {
    margin-top: 30px;
  }
  .getBack {
    display: flex;
    justify-content: center;
    margin-top: 160px;
    .getBackBtn {
      width: 100px;
      height: 40px;
      line-height: 0;
      border-radius: 4px;
    }
  }
  .upload-demo {
    width: 360px;
  }
}
